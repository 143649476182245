<template>
    <div>
        <div class="wrapper">
            <Radio-Group v-model="result">
                <div
                    v-for="item in users"
                    :key="item.personId"
                    class="card mb8">
                    <div
                        @click="handleRadioClick(item)"
                        class="left">
                        <Radio
                            :name="item.personId"
                            refs="checkboxes">
                            <template #icon="props">
                                <img
                                    :src="props.checked
                                        ? require('@img/checkbox_active.png')
                                        : require('@img/checkbox_default.png')"
                                    class="icon_checkbox"
                                    alt="">
                            </template>
                        </Radio>
                        <div class="info mb4 ml12">
                            <div>
                                <span class="mr16">{{ item.personName }}</span>
                                <span class="mr16">{{ item.gender === 1 ? '男' : '女' }}</span>
                                <span>{{ item.age }}岁</span>
                            </div>
                            <div class="IDcard mb4">
                                身份证号：{{ item.credentialsNumber | asterisk(11) }}
                            </div>
                            <div class="phone">
                                手机号： {{ item.phone | asterisk(4) }}
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <span
                            @click="handleEdit(item.personId)"
                            class="btn">编辑</span>
                    </div>
                </div>
            </Radio-Group>
        </div>

        <div v-if="!users.length">
            <Empty-data />
        </div>

        <Btn-Bottom
            :disabled="users.length >= 10"
            @clickEvent="handleAdd"
            title="添加用药人" />
    </div>
</template>
<script>
import { Radio, RadioGroup /* Toast */ } from 'vant';
import BtnBottom from '@/components/btn-bottom';
import EmptyData from '@/components/empty-data';
import api from '@/api';
import { mapMutations } from 'vuex';

export default {
    components: {
        Radio,
        RadioGroup,
        BtnBottom,
        EmptyData,
    },
    filters: {
        // 指定区间*号展示
        asterisk(str, len) {
            return str ? (str.substr(0, 3) + '*'.repeat(len) + str.substr(-4)) : '';
        },
    },
    data() {
        return {
            result: this.$store.state.drugUsers.selected.personId,
            // result: this.$store.state.prescription.drugUersSelected.personId,
            users: [],
        };
    },
    created() {
        this.getDrugUsersList();
    },
    methods: {
        ...mapMutations('drugUsers', [
            'updateUserInfo',
            'updateUserSelected',
        ]),
        ...mapMutations('prescription', [
            'updateDrugUser',
        ]),
        handleAdd() {
            // if (this.users.length >= 10) {
            //     Toast('最多添加10个用药人');
            //     return;
            // }
            this.updateUserInfo({});
            this.$router.push({
                name: 'drug-users-info',
                params: {
                    id: 0,
                },
            });
        },
        handleEdit(id) {
            const [filterOp] = this.users.filter((item) => item.personId === id);
            const obj = {
                name: filterOp.personName,
                IDcard: filterOp.credentialsNumber,
                gender: filterOp.gender,
                age: filterOp.age,
                phone: filterOp.phone,
                personId: filterOp.personId,
                operatorId: filterOp.operatorId,
                relation: filterOp.relation,
            };
            this.updateUserInfo(obj);
            this.$router.push({
                name: 'drug-users-info',
                params: {
                    id,
                },
            });
        },
        handleRadioClick({ personId }) {
            this.result = personId;

            const [selected] = this.users.filter((item) => item.personId === personId);
            const {
                personName, age, gender, phone,
            } = selected;
            this.updateUserSelected({
                personName, personId, age, gender, phone,
            });

            setTimeout(() => {
                this.$router.push({
                    name: 'prescription-info',
                    params: {
                        from: 'drug-users-list',
                    },
                });
            }, 100);
        },
        getDrugUsersList() {
            const param = {
                pageNumber: 1,
                pageSize: 20,
                personId: window.localStorage.getItem('userId'),
            };
            api.getDrugUsersList(param).then((res) => {
                const { list } = res.data;
                this.users = list;
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.wrapper {
    padding: 8px 8px 58px;
}
.card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 0;
    background: #fff;
    border-radius: 8px;

    .left {
        display: flex;
        flex: 1;
        padding-left: 12px;

        .info {
            font-size: 16px;
            // font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333;
            line-height: 22px;
        }

        .IDcard,
        .phone {
            font-size: 12px;
            // font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #62636c;
            line-height: 17px;
        }
    }

    .right {
        width: 64px;
        height: 28px;
        line-height: 28px;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        font-size: 14px;
        // font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #3679f0;
        text-align: center;
    }
}
</style>
