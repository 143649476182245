/* eslint-disable no-unreachable */
/* eslint-disable no-var */
/* eslint-disable camelcase */
/* eslint-disable radix */
function checkId(idcard) {
    const Errors = [
        '验证通过',
        '身份证号码位数不对',
        '身份证号码出生日期超出范围或含有非法字符',
        '身份证号码校验错误',
        '身份证地区非法',
        '请输入身份证',
    ];
    const area = {
        11: '北京',
        12: '天津',
        13: '河北',
        14: '山西',
        15: '内蒙古',
        21: '辽宁',
        22: '吉林',
        23: '黑龙江',
        31: '上海',
        32: '江苏',
        33: '浙江',
        34: '安徽',
        35: '福建',
        36: '江西',
        37: '山东',
        41: '河南',
        42: '湖北',
        43: '湖南',
        44: '广东',
        45: '广西',
        46: '海南',
        50: '重庆',
        51: '四川',
        52: '贵州',
        53: '云南',
        54: '西藏',
        61: '陕西',
        62: '甘肃',
        63: '青海',
        64: '宁夏',
        65: '新疆',
        71: '台湾',
        81: '香港',
        82: '澳门',
        91: '国外',
    };
    if (!idcard) {
        return {
            errCode: 5,
            errInfo: Errors[5],
        };
    }

    let Y;
    let JYM;
    let S;
    let M;
    let ereg;
    let idcard_array = [];
    idcard_array = idcard.split('');
    // 地区检验
    if (area[parseInt(idcard.substr(0, 2))] === null) {
        return {
            errCode: 4,
            errInfo: Errors[4],
        };
    }
    // return Errors[4];
    // 身份号码位数及格式检验
    switch (idcard.length) {
    case 15:
        if ((parseInt(idcard.substr(6, 2)) + 1900) % 4 === 0 || ((parseInt(idcard.substr(6, 2)) + 1900) % 100 === 0 && (parseInt(idcard.substr(6, 2)) + 1900) % 4 === 0)) {
            ereg = /^[1-9][0-9]{5}19[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}$/; // 测试出生日期的合法性
        } else {
            ereg = /^[1-9][0-9]{5}19[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}$/; // 测试出生日期的合法性
        }
        if (ereg.test(idcard)) {
            return {
                errCode: 0,
                errInfo: Errors[0],
            };
        }
        return {
            errCode: 2,
            errInfo: Errors[2],
        };

        break;
    case 18:
        if (parseInt(idcard.substr(6, 4)) % 4 === 0 || (parseInt(idcard.substr(6, 4)) % 100 === 0 && parseInt(idcard.substr(6, 4)) % 4 === 0)) {
            ereg = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}[0-9Xx]$/; // 闰年出生日期的合法性正则表达式
        } else {
            ereg = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}[0-9Xx]$/; // 平年出生日期的合法性正则表达式
        }
        if (ereg.test(idcard)) { // 测试出生日期的合法性
            // 计算校验位
            S = (parseInt(idcard_array[0]) + parseInt(idcard_array[10])) * 7 + (parseInt(idcard_array[1]) + parseInt(idcard_array[11])) * 9 + (parseInt(idcard_array[2]) + parseInt(idcard_array[12])) * 10 + (parseInt(idcard_array[3]) + parseInt(idcard_array[13])) * 5 + (parseInt(idcard_array[4]) + parseInt(idcard_array[14])) * 8 + (parseInt(idcard_array[5]) + parseInt(idcard_array[15])) * 4 + (parseInt(idcard_array[6]) + parseInt(idcard_array[16])) * 2 + parseInt(idcard_array[7]) * 1 + parseInt(idcard_array[8]) * 6 + parseInt(idcard_array[9]) * 3;
            Y = S % 11;
            M = 'F';
            JYM = '10X98765432';
            M = JYM.substr(Y, 1); // 判断校验位
            if (M === idcard_array[17]) {
                return {
                    errCode: 0,
                    errInfo: Errors[0],
                };
            }
            return {
                errCode: 3,
                errInfo: Errors[3],
            };
        }
        return {
            errCode: 2,
            errInfo: Errors[2],
        };
        break;
    default:
        return {
            errCode: 1,
            errInfo: Errors[1],
        };
        break;
    }
}

/**
 * 校验身份证格式，并检索性别、年龄
 * @param {*} idcard
 */
function verifyID(idcard) {
    const { errCode, errInfo } = checkId(idcard);
    if (errCode !== 0) {
        return { errCode, errInfo };
    }

    // 获取出生日期
    const birthDate = `${idcard.substring(6, 10)}-${idcard.substring(10, 12)}-${idcard.substring(12, 14)}`;
    // 获取性别
    const gender = parseInt(idcard.substr(16, 1)) % 2 === 1 ? 1 : 2;
    // 获取年龄
    const myDate = new Date();
    const month = myDate.getMonth() + 1;
    const day = myDate.getDate();
    let age = myDate.getFullYear() - idcard.substring(6, 10) - 1;
    if (
        idcard.substring(10, 12) < month
        || (idcard.substring(10, 12) === month
        && idcard.substring(12, 14) <= day)
    ) {
        age++;
    }
    return { birthDate, gender, age };
}

export default verifyID;
