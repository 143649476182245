/*
 * @Author: ajx
 * @Email: jianxin.an@medbanks.cn
 * @Date: 2020-11-17 15:01:42
 * @Last Modified by: ajx
 * @Last Modified time: 2020-12-22 13:42:23
 * @Description: Description
 * @Route: Route
 */

<template>
    <div class="container">
        <Cell-Group>
            <Field
                v-model.trim="userInfo.name"
                required
                label="姓名"
                label-class="self_label"
                placeholder="请输入真实姓名" />
            <Field
                v-model.trim="userInfo.IDcard"
                @blur="verifyIDcard"
                :maxlength="18"
                required
                label="身份证号"
                label-class="self_label"
                placeholder="填写后无法修改，谨慎填写" />
            <Field
                v-model="mapGender"
                disabled
                label="性别"
                label-class="self_label"
                placeholder="根据身份证生成，无需填写" />
            <Field
                v-model="mapAge"
                disabled
                label="年龄"
                label-class="self_label"
                placeholder="根据身份证生成，无需填写" />
            <Field
                v-model="userInfo.phone"
                :maxlength="11"
                required
                label="手机号"
                label-class="self_label"
                type="number"
                placeholder="请填写您的手机号" />
        </Cell-Group>

        <Btn-Bottom
            :btns="btns"
            @clickEvent="handleClick" />
        <Dialog
            v-model="showDialog"
            :confirm-button-color="'#F76D32'"
            :cancel-button-color="'#62636C'"
            @confirm="handleConfirm"
            @cancel="handleCancel"
            width="280px"
            show-cancel-button>
            <div class="content">
                确认删除
            </div>
        </Dialog>
    </div>
</template>
<script>
import {
    CellGroup, Field, Dialog, Toast,
} from 'vant';
import BtnBottom from '@/components/btn-bottom';
import api from '@/api';
import { mapMutations, mapState } from 'vuex';
import verifyIDnumber from '@/utils/verifyID';

export default {
    components: {
        CellGroup,
        Field,
        Dialog: Dialog.Component,
        BtnBottom,
    },
    data() {
        const { id } = this.$route.params;
        document.title = Number(id) === 0 ? '添加用药人' : '编辑用药人';
        const btnsAdd = [
            { id: 1, title: '添加用药人' },
        ];
        const btnsEdit = [
            { id: 2, title: '删除用药人', type: 'plain' },
            { id: 3, title: '保存' },
        ];
        const btns = Number(id) === 0 ? btnsAdd : btnsEdit;

        return {
            showDialog: false,
            btns,
        };
    },
    computed: {
        ...mapState({
            userInfo: (store) => store.drugUsers.userInfo,
        }),
        mapGender() {
            const genderOp = ['', '男', '女'];
            const { gender } = this.userInfo;
            // eslint-disable-next-line no-nested-ternary
            return gender
                ? (genderOp[gender] || '')
                : '';
        },
        mapAge() {
            const { age } = this.userInfo;
            return age ? `${age}岁` : '';
        },
    },
    beforeDestroy() {
        this.clearUserInfo();
    },
    methods: {
        ...mapMutations('drugUsers', [
            'updateUserInfo',
            'clearUserInfo',
        ]),
        handleClick({ id }) {
            if (id === 1 || id === 3) {
                if (!this.userInfo.name) {
                    return Toast('请输入姓名');
                }
                const regName = /^[\u4e00-\u9fa5]{2,4}$/;
                if (!regName.test(this.userInfo.name)) {
                    return Toast('请输入正确姓名');
                }

                const { errCode, errInfo } = verifyIDnumber(this.userInfo.IDcard);
                if (errCode) {
                    return Toast({
                        message: errCode === 5 ? errInfo : '请输入正确身份证号',
                        duration: 2000,
                    });
                }

                if (!this.userInfo.phone) {
                    return Toast('请输入手机号');
                }
                const regPhone = /^1[0-9]{10}$/;
                if (!regPhone.test(this.userInfo.phone)) {
                    return Toast('请输入正确手机号');
                }
            }
            // 添加用药人
            if (id === 1) {
                const { drugstoreCode } = this.$store.state.merchantInfo.merchantObj;
                const param = {
                    drugstoreCode,
                    ...this.userInfo,
                };
                api.addDrugUsers(param).then((res) => {
                    const { code } = res.data;
                    if (code === '0') {
                        this.$router.push({
                            name: 'drug-users-list',
                        });
                    }
                    if (code === '0011') {
                        const { data } = res.data;
                        const { personId } = data;
                        api.updateDrugUsers({ ...param, personId }).then((resp) => {
                            if (resp.code === '0') {
                                this.$router.push({
                                    name: 'drug-users-list',
                                });
                            }
                        });
                    }
                });
            }
            // 删除用药人
            if (id === 2) {
                this.showDialog = true;
            }
            // 保存
            if (id === 3) {
                const param = { ...this.userInfo };
                api.updateDrugUsers(param).then((res) => {
                    console.log(res);
                    if (res.code === '0') {
                        this.$router.push({
                            name: 'drug-users-list',
                        });
                    }
                });
            }
        },
        handleConfirm() {
            const param = {
                personId: this.userInfo.personId,
                operatorId: this.userInfo.operatorId,
            };
            api.deleteDrugUsers(param).then((res) => {
                if (res.code === '0') {
                    this.$router.push({
                        name: 'drug-users-list',
                    });
                }
            });
        },
        handleCancel() {
            this.showDialog = false;
        },
        verifyIDcard() {
            const { gender = '', age = '' } = verifyIDnumber(this.userInfo.IDcard);
            this.updateUserInfo({ gender, age });
        },
    },
};
</script>
<style lang="scss" scoped>
.container {
    padding-top: 8px;

    /deep/ .self_label {
        font-size: 16px;
        // font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333;
    }

    /deep/ .van-cell {
        padding: 13px 18px;
    }

    /deep/ .van-field__control {
        padding-right: 24px;
        text-align: right;
        font-size: 16px;
        // font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333;

        &::placeholder {
            font-size: 16px;
            // font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #babfc9;
        }
    }

    /deep/ .van-dialog {
        border-radius: 4px;
    }

    /deep/ .van-button__text {
        font-size: 18px;
    }
}
.content {
    padding: 24px 22px;
    font-size: 18px;
    // font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000;
    line-height: 25px;
    background: #fff;
    text-align: center;
}
</style>
